import { Icon } from '@iconify/react';
import peopleFill from '@iconify-icons/eva/people-fill';
import monitorFill from '@iconify-icons/eva/monitor-fill';
import BarChartIcon from '@iconify-icons/eva/bar-chart-fill';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import AssistantPhotoIcon from '@mui/icons-material/AssistantPhoto';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import LanIcon from '@mui/icons-material/Lan';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import { localPrivs, checkPriv } from '../../utils/privs'

const getIcon = (name: any) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig: any = [
  // declare_menu_module
  {
    subheader: 'Geral',
    items: []
  },
  {
    subheader: 'Administrador',
    items: []
  },
];

const handleSidebarConfig = (permission: Number) => {
  if (checkPriv(permission, localPrivs['getBots'])) {
    if (sidebarConfig[0].items.filter((i: any) => i.path === '/bots').length === 0) {
      sidebarConfig[0].items.push({
        title: 'Bots',
        path: '/bots',
        icon: <SmartToyIcon />,
      })
    }
  }

  if (checkPriv(permission, localPrivs['getFunnels'])) {
    if (sidebarConfig[0].items.filter((i: any) => i.path === '/funnels').length === 0) {
      sidebarConfig[0].items.push({
        title: 'Funis',
        path: '/funnels',
        icon: <FilterAltIcon />,
      })
    }
  }

  if (checkPriv(permission, localPrivs['getUsers'])) {
    if (sidebarConfig[1].items.filter((i: any) => i.path === '/admin/users').length === 0) {
      sidebarConfig[1].items.push(  {
        title: 'Usuários',
        path: '/admin/users',
        icon: getIcon(peopleFill),
      })
    }
  }

  if (checkPriv(permission, localPrivs['viewReport'])) {
    if (sidebarConfig[1].items.filter((i: any) => i.path === '/admin/report').length === 0) {
      sidebarConfig[1].items.push({
        title: 'Relatório',
        path: '/admin/report',
        icon: getIcon(BarChartIcon),
      })
    }
  }

  if (checkPriv(permission, localPrivs['getInstances'])) {
    if (sidebarConfig[1].items.filter((i: any) => i.path === '/admin/instances').length === 0) {
      sidebarConfig[1].items.push({
        title: 'Instâncias',
        path: '/admin/instances',
        icon: <LanIcon />,
      })
    }
  }

  if (checkPriv(permission, localPrivs['getEvents'])) {
    if (sidebarConfig[1].items.filter((i: any) => i.path === '/admin/events').length === 0) {
      sidebarConfig[1].items.push({
        title: 'Eventos',
        path: '/admin/events',
        icon: <AssistantPhotoIcon />,
      })
    }
  }

  if (checkPriv(permission, localPrivs['setMasterLink'])) {
    if (sidebarConfig[1].items.filter((i: any) => i.path === '/admin/masterlink').length === 0) {
      sidebarConfig[1].items.push({
        title: 'Link Mestre',
        path: '/admin/masterlink',
        icon: <InsertLinkIcon />,
      })
    }
  }

  return sidebarConfig
}

export type SideBarConfig = typeof sidebarConfig;

export default handleSidebarConfig;