import { Navigate, useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';

import DashboardLayout from '../layouts/dashboard';
import { ReduxReducers } from '../store/reducers';
import { AuthState } from '../store/reducers/auth';
import LogoOnlyLayout from '../layouts/only-logo-layout';
import { 
  LoginScreen,
} from '../pages/auth';
import {
  HomeScreen,
  UsersScreen,
  BotsScreen,
  ReportScreen,
  FunnelsScreen,
  EventsScreen,
  InstancesScreen,
  MasterLink,
} from '../pages/dashboard';
import NotFound from '../pages/404';

const AppRoutes = () => { 
  const { token, user } = useSelector<ReduxReducers, AuthState>(s => s.auth);

  const IS_AUTHENTICATED = !!token && !!user;

  const AUTHENTICATED_ROUTES = {
    path: '/',
    element: <DashboardLayout />,
    children: [
      { element: <Navigate to="/app" replace /> },
      { path: 'app', element: <HomeScreen /> },
      { path: '/admin/users', element: <UsersScreen />},
      { path: '/bots', element: <BotsScreen /> },
      { path: '/admin/report', element: <ReportScreen />},
      { path: '/funnels', element: <FunnelsScreen />},
      { path: '/admin/events', element: <EventsScreen />},
      { path: '/admin/instances', element: <InstancesScreen />},
      { path: '/admin/masterlink', element: <MasterLink />},
    ]
  };

  const UNAUTHENTICATED_ROUTES = {
    path: '/',
    element: <LogoOnlyLayout />,
    children: [
      { path: '', element: <LoginScreen /> },
      { path: 'login', element: <LoginScreen /> },
      { path: '404', element: <NotFound /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  };

  return useRoutes([
    IS_AUTHENTICATED ? AUTHENTICATED_ROUTES : UNAUTHENTICATED_ROUTES,
  ]);
};

export default AppRoutes;