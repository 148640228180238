import {
    Stack,
    Container,
} from '@mui/material';

import { 
    Page,
} from '../../../components';
import HeaderBreadcrumbs from '../../../components/header-breadcrumbs';
import { InstanceList } from '../../../components/lists/instance-list/instance-list';

interface Props {}

const InstancesScreen: React.FC<Props> = () => {

    return (
        <>
            <Page title='PubZap - Instâncias'>
                <Container maxWidth={false}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <HeaderBreadcrumbs
                            heading="Instâncias"
                            links={[
                                {
                                    name: 'Dashboard',
                                    href: '/admin/instances'
                                },
                                {
                                    name: 'Administrador',
                                    href: '/admin/instances'
                                },
                                { name: 'Instâncias', href: '/admin/instances' },
                            ]}
                            activeLast={false}
                        />
                    </Stack>
                    <InstanceList />
                </Container>
            </Page>
        </>
    )
}

export default InstancesScreen;