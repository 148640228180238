import React, { useState, useEffect } from 'react';
import { When } from 'react-if';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';

import Loading from '../../loading';
import { Instance, InstanceFilters } from '../../../typings';
import { ReduxReducers } from '../../../store/reducers';
import { PaginatorFactory } from '../../../factory';
import NoData from '../../no-data';
import { InstanceState } from '../../../store/reducers/instances';
import { fetchInstances } from '../../../store/actions/instances';
import { INSTANCE_COLUMNS } from '../../../builders/instance.builders';
import { InstanceListFilters } from './instance-list-filters';
import { Label } from '../../label';

export const InstanceList: React.FC = () => {
    const dispatch = useDispatch();
    const { instances, loading, totalItems } = useSelector<ReduxReducers, InstanceState>(s => s.instances);

    const [pagination, setPagination] = useState<PaginatorFactory<Omit<Instance, 'id'>>>({
        page: 0,
        perPage: 50,
    });

    const onFilter = async (filters: InstanceFilters) => {
        dispatch(
            fetchInstances(
                pagination?.page + 1,
                pagination?.perPage,
                filters,
            )
        );
    }

    useEffect(() => {
        dispatch(
            fetchInstances(
                pagination?.page + 1,
                pagination?.perPage,
            )
        );
    }, [pagination]);

    return (
        <>
            <Card>
                <InstanceListFilters onFilter={onFilter} />
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow> 
                                {INSTANCE_COLUMNS?.map((column) => (
                                    <TableCell>{column?.headerName}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <When condition={!loading.index}>
                            <TableBody>
                                {instances?.map((instance) => (
                                    <TableRow key={instance?.id}>
                                        <TableCell>
                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                {instance?.id}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                {instance?.token}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Label
                                                variant={'ghost'}
                                                color={instance?.free === 1 ? 'success' : 'error'}
                                            >
                                                <>
                                                    {
                                                        instance?.free === 1 ? 'Sim' : 'Não'
                                                    }
                                                </>
                                            </Label>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                {instance?.setup}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                {dayjs(instance?.created_at).format('DD/MM/YYYY HH:MM')}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                {dayjs(instance?.updated_at).format('DD/MM/YYYY HH:MM')}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </When>
                    </Table>

                    <When condition={loading?.index !== true && instances?.length < 1}>
                        <NoData 
                            buttonText=''
                            onClick={() => {}}
                            title={'Nenhuma instância foi encontrado.'}
                            description={'Aparentemente, não há nenhuma instância que contemple o filtro indicado.'}
                        />
                    </When>

                    <When condition={loading?.index}>
                        <Loading />
                    </When>
                </TableContainer>

                <TablePagination
                    rowsPerPageOptions={[50, 100, 150]}
                    component="div"
                    count={totalItems}
                    
                    rowsPerPage={pagination?.perPage}
                    page={pagination?.page}
                    onPageChange={(e, p) => setPagination(pag => ({ ...pag, page: p }))}
                    onRowsPerPageChange={(e) => setPagination(pag => ({ ...pag, perPage: parseInt(e.target.value, 10) }))}
                />
            </Card>
        </>
    )
}