export const FUNNEL_COLUMUNS = [
    {
        field: 'id',
        headerName: 'id',
        width: 250,
    },
    { 
        field: 'name',
        headerName: 'Nome',
        width: 350,
    },
    { 
        field: 'uuid',
        headerName: 'UUID',
        width: 350,
    },
    { 
        field: 'active',
        headerName: 'Ativo',
        width: 250,
    },
    {
        field: 'created_at',
        headerName: 'Data de criação',
        width: 250,
    },
    {
        field: 'updated_at',
        headerName: 'Data atualização',
        width: 250,
    },
];