import { AnyAction } from 'redux';
import { Bot } from '../../../typings';
import { BOT_ACTIONS } from '../../actions/bots';

export interface BotState {
    bots: Bot[] | [],
    bot: Bot | null,
    qrCodeToRead: string | null,
    botToConnectId: number | null,
    totalPages: number,
    report: {
        totalBotsNoListed: number,
        totalBotsOnline: number,
        totalBotsConnecting: number,
        totalBotsOffline: number,
        totalUsedInstances: number,
        totalFreeInstances: number;
        totalBotsHot: number;
        totalBotsCold: number;
        totalBotsPartner: number;
        totalBotsInteractiveBots: number;
    },
    modal: {
        form: boolean,
        connect: boolean,
    },
    loading: {
        update: boolean,
        create: boolean,
        index: boolean,
        connect: boolean,
        logout: boolean,
        destroy: boolean,
    },
};

export const initialState: BotState = {
    bots: [],
    bot: null,
    qrCodeToRead: null,
    botToConnectId: null,
    totalPages: 0,
    report: {
        totalBotsNoListed: 0,
        totalBotsOnline: 0,
        totalBotsConnecting: 0,
        totalBotsOffline: 0,
        totalUsedInstances: 0,
        totalFreeInstances: 0,
        totalBotsHot: 0,
        totalBotsCold: 0,
        totalBotsPartner: 0,
        totalBotsInteractiveBots: 0,
    },
    modal: {
        form: false,
        connect: false,
    },
    loading: {
        update: false,
        create: false,
        destroy: false,
        index: false,
        connect: false,
        logout: false,
    },
};

const reducer = (state = initialState, { type, payload }: AnyAction) => {
    switch (type) {
        // UPDATE BOT
        case BOT_ACTIONS.UPDATE_BOT:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    update: true,
                },
            }

        case BOT_ACTIONS.UPDATE_BOT_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    update: false,
                },
            }

        case BOT_ACTIONS.UPDATE_BOT_SUCCESS:
            return {
                ...state,
                ...payload,
                loading: {
                    ...state.loading,
                    update: false,
                },
            }

        // DELETE BOT
        case BOT_ACTIONS.DELETE_BOT:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    destroy: true,
                },
            }

        case BOT_ACTIONS.DELETE_BOT_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    destroy: false,
                },
            }

        case BOT_ACTIONS.DELETE_BOT_SUCCESS:
            return {
                ...state,
                ...payload,
                loading: {
                    ...state.loading,
                    destroy: false,
                },
            }


        // CONNECT TO QR CODE
        case BOT_ACTIONS.CONNECT_BOT:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    connect: true,
                }
            }
        case BOT_ACTIONS.CONNECT_BOT_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    connect: false,
                }
            }
        case BOT_ACTIONS.CONNECT_BOT_SUCCESS:
            return {
                ...state,
                ...payload,
                loading: {
                    ...state.loading,
                    connect: false,
                }
            }
        // FETCH BOTS
        case BOT_ACTIONS.FETCH_BOTS:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    index: true,
                }
            }

        // LOGOUT BOT
        case BOT_ACTIONS.LOGOUT_BOT:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    logout: true,
                }
            }

        case BOT_ACTIONS.LOGOUT_BOT_SUCCESS:
            return {
                ...state,
                ...payload,
                loading: {
                    ...state.loading,
                    logout: false,
                }
            }

        case BOT_ACTIONS.LOGOUT_BOT_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    logout: false,
                }
            }

        // FETCH BOTS
        case BOT_ACTIONS.CHECK_STATUS_SUCCESS:
            return {
                ...state,
                ...payload,
            }
        case BOT_ACTIONS.FETCH_BOTS_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    index: false,
                }
            }
        case BOT_ACTIONS.FETCH_BOTS_SUCCESS:
            return {
                ...state,
                ...payload,
                loading: {
                    ...state.loading,
                    index: false,
                }
            }
        // MODAL HANDLER
        case BOT_ACTIONS.TOGGLE_MODAL:
            return {
                ...state,
                modal: {
                    ...payload,
                }
            }

        // CREATE A BOT HANDLER
        case BOT_ACTIONS.CREATE_BOT:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    create: true,
                },
            }
        case BOT_ACTIONS.CREATE_BOT_SUCCESS:
            return {
                ...state,
                ...payload,
                loading: {
                    ...state.loading,
                    create: false,
                },
            }
        case BOT_ACTIONS.CREATE_BOT_FAILED:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    create: false,
                },
            }

        // DEFAULT ACTIONS
        case BOT_ACTIONS.SET_VALUE:
            return {
                ...state,
                ...payload,
            };

        case BOT_ACTIONS.CLEAR_VALUES:
            return {
                ...initialState,
            };
        default:
            return state;
    }
}

export default reducer;